export const environment = {
  production: true, /* Production is only used to allow re-takes when already passed atm. */
  academyAPI: 'https://staging.academy.core.automotivecreditcompliance.com',
  idSrv: 'https://stagingidentity.automotivecreditcompliance.com/identity',
  clientUrl: 'https://staging.academyclient.core.automotivecreditcompliance.com',
  cclUrl: 'https://staging.mobile.automotivecreditcompliance.com/',
  openIdConnectSettings: {
    authority: 'https://stagingidentity.automotivecreditcompliance.com/identity',
    client_id: 'academyimplicit',
    redirect_uri: 'https://staging.academyclient.core.automotivecreditcompliance.com/signin-oidc',
    post_logout_redirect_uri: "https://staging.academyclient.core.automotivecreditcompliance.com/",
    scope: 'openid profile address roles dealershipName acadmyserviceapi',
    response_type: 'id_token token',
    filterProtocolClaims: true,
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    monitorSession: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://staging.academyclient.core.automotivecreditcompliance.com/redirect-silentrenew'
  },
  rotorEdgeWidth: 450,
  minDialogWidth: 250
};
